import React from "react"

const SecondaryListBlock = props => {
  const { taxonomy, title, providers = [], secondaryList = {} } = props
  return (
    <div>
      <h2>{title}</h2>
      {taxonomy?.["secondary-provider-list-intro"] ? (
        <div dangerouslySetInnerHTML={{ __html: taxonomy?.["secondary-provider-list-intro"] }} />
      ) : null}

      <ol>
        {providers?.slice(0, 10)?.map(p => {
          return <li>{p.name}</li>
        })}
      </ol>

      {taxonomy?.["secondary-provider-list-content"] ? (
        <div dangerouslySetInnerHTML={{ __html: taxonomy?.["secondary-provider-list-content"] }} />
      ) : null}
      {secondaryList?.links?.length ? (
        <ul>
          {secondaryList?.links?.map(l => {
            return <li><a href={l.path}>{l.title}</a></li>
          })}
        </ul>
      ) : null}
    </div>
  )
}

export default SecondaryListBlock
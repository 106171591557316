import React from "react"
import styled from "@emotion/styled"
import { ArrowRight } from "./styledComponents"

const Card = styled.div`
  padding: 5px;

  &:hover {
    color: ${props => props.theme.primaryColor};

    & > span {
      color: ${props => props.theme.primaryColor};
    }
    & > span:hover {
      color: ${props => props.theme.primaryColor};
    }
  }
`

const Title = styled.h3`
  font-size: 16px;
  display: inline-block;
  color: #000;

  a {
    color: #000;
    font-weight: normal !important;
  }

  &:hover {
    text-decoration: none;
  }
`

export default props => {
  const { taxonomy, rtl } = props

  return (
    <Card className={props.className} >
      <Title><a href={taxonomy.path}>{taxonomy?.["list-title"] || taxonomy.name}</a></Title>
      {rtl ? null : <ArrowRight />}
    </Card>
  )
}

import React from "react"
import styled from "@emotion/styled"
import { useTheme } from '@emotion/react'
import { Row, Col } from "react-flexbox-grid"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Container = styled.div`
  a {
    text-decoration: underline;
    color: ${props => props.theme.secondaryColor};
  }
  img {
    width: 100%;
  }
  .content img {
    max-height: 50vh;
    object-fit: contain;
  }
`

const ContentSections = props => {
  let { data=[], skip=false, children, scrollPosition} = props
  const theme = useTheme()
  const sectionIconSuffix = theme?.sectionIconSuffix || ""
  if (children && data.length && data.indexOf("renderChildren") === -1) {
    data.splice(Math.ceil(data.length / 2), 0, "renderChildren")
  }
  return (
    <Container>
      {data?.map((s,i) => {
        const numberOfSectionIcons = 7
        const iconNumber = (i + 1) <= numberOfSectionIcons ? (i + 1) : (i - (Math.floor(i/numberOfSectionIcons)*numberOfSectionIcons) + 1)
        // Skip X sections if rendered in another block
        if (skip && i <= skip -1) return null

        if (s === "renderChildren") {
          return (
            <Row className="mb-3 mb-md-5 py-2 py-md-4" id={s?.headingId}>

              <Col xs={12} md={9} className="pr-md-5 d-flex justify-content-center flex-column">
                {children}
              </Col>
              <Col xs={0} md={3} className={"pr-md-5 d-none d-md-flex justify-content-center flex-column" + (i % 2 === 0 ? " pl-5" : " first-md pr-5")}>
                <LazyLoadImage scrollPosition={scrollPosition} src={`/img/section${iconNumber+sectionIconSuffix}.svg`} alt={s?.headingRaw || "Section icon"}/>
              </Col>
            </Row>
          )
        }

        return (
          <Row className="mb-3 mb-md-5 py-2 py-md-4" id={s?.headingId}>
            <Col xs={12} md={9} className="pr-md-5 d-flex justify-content-center flex-column">
              <Row className="d-flex">
                {s?.heading ? (
                  <Col xs={2} className={"d-flex d-md-none align-items-center" + (i % 2 === 0 ? " last-xs" : "")}>
                    <LazyLoadImage scrollPosition={scrollPosition} src={`/img/section${iconNumber+sectionIconSuffix}.svg`} alt={s?.headingRaw || "Section icon"} style={{width:"100%"}} wrapperClassName="d-md-none" />
                  </Col>
                ):null}

                <Col xs={s?.heading ? 10:12} md={12} className="d-flex align-items-center">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: s?.heading,
                    }}
                  />
                </Col>
              </Row>
              
              <div
                dangerouslySetInnerHTML={{
                  __html: s?.content,
                }}
                className="content"
              />
            </Col>

            <Col xs={0} md={3} className={"pr-md-5 d-none d-md-flex justify-content-center flex-column" + (i % 2 === 0 ? " bakpl-5" : " first-md pr-5")}>
              <LazyLoadImage scrollPosition={scrollPosition} src={`/img/section${iconNumber+sectionIconSuffix}.svg`} alt={s?.headingRaw || "Section icon"}/>
            </Col>
          </Row>
        )
      })}
    </Container>
  )
}

export default ContentSections

import React from "react"
import { Row } from "react-flexbox-grid"
import ChildTaxonomyListItem from "./childTaxonomyListItem"
import ChildTaxonomyListItemFeatured from "./childTaxonomyListItemFeatured"

export default props => {
  const { pageContext } = props
  const { site } = pageContext
  let { data } = props
  const { featuredTaxonomies, remainingTaxonomies, showThumbnails } = data
  const rtl = site?.properties?.rtl?.data?.value

  return (
    <React.Fragment>
      <Row>
        {featuredTaxonomies?.map((taxonomy, i) => {
          if (!taxonomy.path) return null
          return (
            <ChildTaxonomyListItemFeatured
              {...props}
              taxonomy={taxonomy}
              key={i}
              className="col-xs-12 col-md-3 mb-1 mb-md-5 p-3"
              site={site}
              rtl={rtl}
              showThumbnails={showThumbnails}
            />
          )
        })}
      </Row>

      <Row>
        {remainingTaxonomies?.map((taxonomy, i) => {
          if (!taxonomy.path) return null
          return (
            <ChildTaxonomyListItem
              taxonomy={taxonomy}
              key={i}
              className="col-xs-6 col-md-3 py-1"
              rtl={rtl}
            />
          )
        })}
      </Row>
    </React.Fragment>
  )
}

import React, { Component } from "react"
import { ExpandableP, ReadMore } from "./styledComponents"

export default class ExpandableSection extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  toggle() {
    if (this.props.onExpand) {
      this.props.onExpand(!this.state.expanded)
    }
    this.setState({ expanded: !this.state.expanded })
  }
  render() {
    const { content, className, color, pageContext } = this.props
    const { site } = pageContext
    const readMore = site["read-more"] || "Read more..."
    const showLess = site["show-less"] || "Show less -"

    if (!content) return null
    return (
      <div className={className}>
        <ExpandableP
          expanded={this.state.expanded}
          className="pl-0"
          color={color || "#000"}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
        <ReadMore onClick={() => this.toggle()} color={color}>
          {this.state.expanded ? showLess : readMore}
        </ReadMore>
      </div>
    )
  }
}

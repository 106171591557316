import React, { useState } from "react"
import Layout from "../../components/layout"
import { trackWindowScroll } from "react-lazy-load-image-component"
import styled from "@emotion/styled"
import cloudinary from "../../../utils/cloudinary"
import GeoContext from "../../context/geoContext";
import { rankProviders } from "../../../utils/index";
import Breadcrumbs from "../../components/breadcrumbs"
import DropdownMenu from "../../components/dropdownMenu"
import VerticalsDropdown from "../../components/verticalsDropdown"
import { Grid, Row, Col } from "react-flexbox-grid"
import { ArrowRight, ContentSectionsMenu, HeroSection, Section } from "../../components/styledComponents"
import FeaturedSlots from "../../components/featuredSlots"
import Faq from "../../components/faq"
import ProviderList from "../../components/providerList"
import ProviderListSmall from "../../components/providerListSmall"
import ChildTaxonomyList from "../../components/childTaxonomyList"
import ExpandableText from "../../components/expandableText"
import PostGrid from "../../components/postGrid";
import ProviderTable from "../../components/providerTable"
import SecondaryListBlock from "../../components/secondaryListBlock"
import ContentSections from "../../components/contentSections";

const FeaturedImage = styled.img`
  width: 100%;
  border-radius: 5px;
  width: 318px;
  height: 241px;
  object-fit: contain;

  @media only screen and (min-width: 1200px) {
    width: 376px;
    height: 286px;
  }

  @media only screen and (min-width: 1400px) {
    width: 390px;
    height: 337px;
  }
`

const StyledSection = styled(Section)`
  background: ${props => props.theme.tertiaryColor};

  &.pt-xxl-0 {
    @media only screen and (min-width: 1401px) {
      padding-top: 0 !important;
    }
  }
`;

const Taxonomy = props => {
  const { path, pageContext, scrollPosition } = props
  const {
    pageType,
    taxonomy: _taxonomy,
    modules,
    filterTaxonomy,
    themeSettings,
    site,
  } = pageContext
  const { complianceMode = false } = themeSettings
  const {
    childTaxonomies,
    taxonomyProviders,
    taxonomyProducts,
    filterTaxonomyList,
    verticalLinks,
    contentBlocks,
    postList,
    contentSectionsAnchorMenu,
    secondaryList,
    contentSections
  } = modules

  const taxonomy = pageType === "taxonomyFilter" ? filterTaxonomy : _taxonomy
  const contentBlockText = contentBlocks ? (contentBlocks?.base ? contentBlocks?.base + "\n\n" : "") + (contentBlocks?.filter ? contentBlocks.filter : "") : ""
  const content = taxonomy?.content ? taxonomy.content : contentBlockText
  const hasIntro = taxonomy?.intro
  const introFromContent = contentSections ? ((contentSections?.[0]?.heading ? contentSections?.[0]?.heading:"") + (contentSections?.[0]?.content ? contentSections?.[0]?.content:"")) : ""
  const intro = hasIntro ? taxonomy?.intro : (introFromContent || content)
  const rtl = site?.properties?.rtl?.data?.value
  const featuredImage = taxonomy?.properties?.["image"]?.resolved?.[0]?.url
  const twitterImage = taxonomy?.properties?.["twitter-image"]?.resolved?.[0]?.url
  const ogImage = taxonomy?.properties?.["og-image"]?.resolved?.[0]?.url
  const [countParam, setCountParam] = useState(() => {
    if (pageType === "taxonomyParent") {
      return [...childTaxonomies?.featuredTaxonomies, ...childTaxonomies?.remainingTaxonomies]?.length
    } else {
      return taxonomyProviders?.length
    }
  })

  const h1 = taxonomy?.title?.replace("%COUNT", countParam === 0 ? "" : countParam).replace("[%s:count]", countParam === 0 ? "" : countParam) || taxonomy.name

  function _rankedProviders({ taxonomyProviders, geoContext }) {
    return rankProviders({
      providers: taxonomyProviders,
      geoContext,
    })
  }

  return (
    <Layout
      {...props}
      headerBackground={"#fff"}
      htmlTitle={taxonomy?.["html-title"]?.replace("%COUNT", countParam === 0 ? "" : countParam).replace("[%s:count]", countParam === 0 ? "" : countParam) || taxonomy?.title?.replace("%COUNT", countParam === 0 ? "" : countParam).replace("[%s:count]", countParam === 0 ? "" : countParam) || taxonomy.name}
      metaDescription={taxonomy["meta-description"]}
      path={taxonomy.path}
      image={featuredImage}
      ogImage={ogImage}
      twitterImage={twitterImage}
      countParam={countParam}
      scrollPosition={scrollPosition}
    >
      <GeoContext.Consumer>
        {geoContext => (
          <>
            <HeroSection className="pb-1 pb-md-3">
              <Grid className="pb-0">
                <Breadcrumbs {...props} invert={1} />

                <Row className={"contentContainer d-flex mx-0 " + (rtl ? "text-right" : "text-left")}>
                  <Col xs={12} md={8} className={"px-0 pr-lg-5 pt-3 pt-md-5 " + (pageType === "taxonomyParent" ? "pb-5" : "pb-2 pb-md-3")}>

                    <h1 className="mt-0">{h1}</h1>

                    {themeSettings.expandableTaxonomyIntros || intro?.length > 600 ? <ExpandableText
                      {...props}
                      content={intro}
                      className={"mainContent position-relative mb-5 " + (rtl ? "text-right" : "text-left")}
                      color="#fff"
                    /> : <div className="mainContent"
                      dangerouslySetInnerHTML={{ __html: intro }}
                      />}
                  </Col>

                  <Col md={4} className="d-none d-md-flex justify-content-center align-items-center">
                    <FeaturedImage loading="lazy" src={featuredImage ? cloudinary.url(featuredImage, { width: 880 /*440*/, height: 746 /*373*/, crop: "pad"}) : (pageType === "taxonomyParent" ? (themeSettings?.theme?.taxonomyParentHeroDefault || "/img/countries.svg"):(themeSettings?.theme?.taxonomyChildHeroDefault || "/img/freespins.svg"))} alt={h1} />
                  </Col>
                </Row>
              </Grid>
            </HeroSection>

            {pageType === "taxonomyParent" ? (
              <StyledSection className="d-md-block pb-5">
                <Grid className="position-relative mb-5">
                  <div className="position-absolute row px-md-2" style={{zIndex: 9, transform: "translateY(-50%)", width: "100%"}}>
                      <ProviderListSmall {...props} dynamic count={3} showBadges={false} horizontal={true} className="px-0" resourceId={taxonomy?.allanId} />
                  </div>
                </Grid>
              </StyledSection>
            ) : null}

            <StyledSection overflowVisible className={pageType === "taxonomyParent" ? "pb-0 pt-5 pt-xxl-0":"pb-3"}>
              <Grid>
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">

                {pageType === "taxonomyParent" ? null : _rankedProviders({ geoContext, taxonomyProviders })?.length ? (
                  <h2 className="mt-0" style={{fontSize:"25px"}}>{taxonomy?.["providerlist-title"] || "Top Casinos"}</h2>) : (
                    !themeSettings?.theme?.hideNoTaxonomyProvidersAvailableTitle ? <h2 className="mt-0" style={{fontSize:"25px"}}>{taxonomy?.["no-providers-available-title"] || "No matches found, please try:"}</h2> : null
                  )}

                  <div>
                    {filterTaxonomyList?.filterTaxonomies?.length ? (
                      <DropdownMenu
                        title={filterTaxonomyList.name || "Countries"}
                        large={1}
                        className="mb-3 d-inline-block mr-1"
                        menuItems={filterTaxonomyList.filterTaxonomies.map(t => {
                          return { title: t.name, url: t.path }
                        })}
                        columns={1}
                        alignright={rtl}
                      />
                    ) : null}

                    {(pageType === "taxonomyParent" || pageType === "taxonomyChild" || pageType === "taxonomyFilter") && verticalLinks && verticalLinks.length > 1 ? (
                      <VerticalsDropdown items={verticalLinks} {...props} stringTemplate={taxonomy["bilateral-link-title"]} taxonomy={taxonomy} pageType={pageType} />
                    ) : null}
                  </div>
                </div>
              </Grid>
            </StyledSection>

            <StyledSection className="pt-0 pb-4">
              <Grid className="px-1">
                {pageType === "taxonomyParent" ? (
                  <>
                    <ChildTaxonomyList {...props} data={childTaxonomies} />
                    {themeSettings?.theme?.showProviderListOnEmptyParentTaxonomies ? (
                      <ProviderList {...props} dynamic count={5} resourceId={taxonomy?.allanId} className="px-0"/>
                    ):null}
                  </>
                ) :
                  (
                    <React.Fragment>
                      {!_rankedProviders({ geoContext, taxonomyProviders })?.length ? (
                        <React.Fragment>
                          <ProviderList {...props} dynamic count={themeSettings?.theme?.hideNoTaxonomyProvidersAvailable || 10} resourceId={taxonomy?.allanId} className="px-0" />
                        </React.Fragment>
                      ) : (
                        !complianceMode ? <ProviderList {...props} providers={taxonomyProviders} providerCountCallback={setCountParam} resourceId={taxonomy?.allanId} className="px-0" /> : null
                      )}

                    </React.Fragment>
                  )}
              </Grid>
            </StyledSection>

            {taxonomyProducts?.length ? (
              <Section className="pt-0 pb-0 pt-5 mt-5">
                <Grid>
                  <FeaturedSlots
                    {...props}
                    products={taxonomyProducts}
                    className="pt-3"
                    multiline={true}
                  />
                </Grid>
              </Section>
            ) : null}

            {contentSectionsAnchorMenu?.length ? (
              <Section className="mt-5 pt-5 px-2 px-md-0">
                <Grid>
                  <ContentSectionsMenu className="px-2 px-md-5 py-3">
                    {contentSectionsAnchorMenu?.map(s => {
                      return <Col md={6} className="py-2"><ArrowRight className="mr-2"/><a href={"#" + s.slug}>{s.heading}</a></Col>
                    })}
                  </ContentSectionsMenu>
                </Grid>
              </Section>
            ):null}

            {contentSections?.length /*taxonomy?.["content"] || taxonomy?.["content-2"] || taxonomy?.["content-3"]*/ ? (
              <Section className="mt-0 mt-md-5">
                <Grid>
                  <Row>
                    <Col xs={12} md={12} className="mt-0 mt-md-0 pr-md-5">
                      <ContentSections data={contentSections} skip={hasIntro ? 0 : 1} scrollPosition={scrollPosition}>
                        {secondaryList ? (
                          <Section className="mt-0 mt-md-5">
                            <Grid>
                              {taxonomy["secondary-provider-list-title"] ? (
                                <SecondaryListBlock taxonomy={taxonomy} title={taxonomy["secondary-provider-list-title"]} providers={taxonomyProviders} secondaryList={secondaryList} />
                              ) : null}
                            </Grid>
                          </Section>
                        ):null}
                      </ContentSections>
                    </Col>
                  </Row>
                </Grid>
              </Section>
            ) : null}

            {!complianceMode && taxonomyProviders?.length ? (
              <Section className="mt-5 mb-3">
                <Grid>
                  <h3>{taxonomy?.["providerlist-title"]}</h3>
                  <ProviderTable providers={taxonomyProviders} path={path} {...props} />
                </Grid>
              </Section>
            ) : null}

            {postList?.length ? (
              <Section>
                <Grid>
                  <Col xs={12} md={12} className="mt-5 pl-md-2 first-xs last-md">
                  <PostGrid
                    {...props}
                    posts={postList}
                    scrollPosition={scrollPosition}
                    className="pt-3 mt-5"
                  />
                  </Col>
                </Grid>
              </Section>
            ) : null}

            {taxonomy?.faq ? (
              <StyledSection className="mt-5">
                <Grid>
                  <h2>FAQ</h2>
                  <Faq content={taxonomy.faq} />
                </Grid>
              </StyledSection>
            ) :
              null
            }
          </>
        )}
      </GeoContext.Consumer>
    </Layout >
  )
}

export default trackWindowScroll(Taxonomy)

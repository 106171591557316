import React from "react"
import { CtaButton } from "../components/styledComponents"
import cloudinary from "../../utils/cloudinary"
import { LazyLoadImage } from "react-lazy-load-image-component"
import styled from "@emotion/styled"
import Rating from "./rating/index"

const Container = styled.div`
  position: relative;
`

const ProvidersContainer = styled.div`
  position: relative;
  flex-direction: row;
  display: flex;
  flex-wrap: ${props => props.multiline ? "wrap":"nowrap"};
  overflow-x: scroll;

  a {
    color: #333;
  }
  a:hover {
    color: #333;
  }

`

const Provider = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  min-width: ${props => props.multiline ? "none":"280px"};

  @media (min-width: 568px) {
    width: 50%;
  }

  @media (min-width: 768px) {
    width: 25%;
  }

  @media (min-width: 1200px) {
    min-width: unset;
    width: ${props => props.count && props.count === 3 ? "33%" : "20%"};
  }
`

const ContentContainer = styled.div`
  position: relative;
  background: #fff;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 12px;
  padding-top: 110px !important;

  .providerName {
    display: block;
    font-weight: bold;
    font-size:1.2em
  }

  .bonusContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .bonus {
    display: block;
    font-weight: normal;
    font-size: 1.1em;
  }
`

const LogoContainer = styled.div`
  position: absolute;
  background: #fff;
  text-align: center;
  padding: 0px;
  padding-bottom:0 !important;
  left: 50%;
  transform: translateX(-50%);
  top: -40px;
  height: 140px;
  width: 80%;
  display: flex;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.backgroundcolor ? props.backgroundcolor : "#fff"};
  overflow: hidden;
`

const Logo = styled(LazyLoadImage)`
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
`

export default props => {
  const { scrollPosition, pageContext, count = 5, products, multiline = false } = props
  const { site } = pageContext

  return (
    <Container multiline={multiline}>
      <ProvidersContainer className="row pt-5" multiline={multiline}>
        {products?.slice(0, multiline ? products.length : count)?.map((provider, i) => {
          const backgroundColor = provider?.properties?.["background-color"]?.data?.value
          const {properties} = provider
          const { image: _image, images: _images } = properties
          const images = _image ? _image : _images

          let imgSrc = images?.resolved?.[0]?.url ? cloudinary.url(images?.resolved?.[0]?.url, { width: 534 /*, height: 267, crop: "fill"*/ }) : "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="

          return (
            <Provider key={i} count={count} className={multiline ? "mb-5 mt-3":""} multiline={multiline}>
              <ContentContainer className="pb-3 px-3">
                <a href={provider.path}>
                  <LogoContainer backgroundcolor={backgroundColor}>
                    <Logo
                      alt={provider.name}
                      scrollPosition={scrollPosition}
                      src={
                        imgSrc
                      }

                    />
                  </LogoContainer>
                </a>

                <div className="content text-center mt-3">
                  <a href={provider.path}>
                    <span className="providerName mb-3">{provider.name}</span>
                  </a>
                  {provider?.properties?.["software-taxonomies"]?.resolved?.[0]?.content ? (
                    <div className="bonusContainer mb-3">
                      <span className="bonus">{provider?.properties?.["software-taxonomies"]?.resolved?.[0]?.content?.find(c => c?.key === "name")?.strings?.[0]?.data}</span>
                    </div>
                  ):null}
                  <Rating value={provider?.properties?.["total-score"]?.data?.value && parseFloat(provider?.properties?.["total-score"]?.data?.value/2).toFixed(1)} />
                  <CtaButton
                    href={
                      provider?.path
                    }
                    className="mt-3"
                  >
                    {site["read-more"] || "Read more"}
                  </CtaButton>

                </div>
              </ContentContainer>
            </Provider>
          )}
        )}
      </ProvidersContainer>
    </Container>
  )
}

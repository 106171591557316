import React from "react"
import styled from "@emotion/styled"
import { ArrowRight } from "./styledComponents"
import { MarkdownContent } from "./styledComponents"
import cloudinary from "../../utils/cloudinary"
import { LazyLoadImage } from "react-lazy-load-image-component"


const Container = styled.div`
  border-radius: ${props => props.theme.borderRadius};
  transition: all .2s ease-in-out;

  .title {
    font-size: 24px;
    font-weight: bold;
    display: block;
    color: ${props => props.theme.primaryColor};

    &:hover {
      text-decoration: none;
    }
  }

  .flagIcon {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    object-fit: cover;
  }

  .readMoreLink {
    display: none;
  }

  @media only screen and (min-width: 992px) {
    &:hover {
      background: #fff;
      transform: scale(1.05);

      .readMoreLink {
        display: inline-block;
      }
    }
  }

`

const Thumbnail = styled.div`
  display: block;
  width: 100%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;


  img {
    width: 120px;
    height: 120px;
    position: relative;
    display:block;
    box-sizing: border-box;
    object-fit: contain;
    margin: 0 auto;
  }


  @media only screen and (min-width: 568px) {
    img {
      width: 160px;
      height: 160px;
    }
  }

  @media only screen and (min-width: 768px) {
    img {
      width: 90px;
      height: 90px;
    }
  }

  @media only screen and (min-width: 992px) {
    img {
      width: 99px;
      height: 99px;
    }
  }

  @media only screen and (min-width: 1200px) {
    img {
      width: 266px;
      height: 146px;
    }
  }
`

const Excerpt = styled(MarkdownContent)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9; /* number of lines to show */
  line-height: 20px !important; /* fallback */
  max-height: 180px;
  margin-bottom: 15px;

  & > p {
    font-size: 14px !important;
    font-weight: normal;
    line-height: 20px !important;
    margin-top: 0;
  }

  & h1 {
    font-size: 20px;
    line-height: 24px;
  }

  & h1:first-of-type {
    margin-top: 0;
  }

  & h2 {
    font-size: 20px;
    line-height: 24px;
  }

  & h2:first-of-type {
    margin-top: 0;
  }
`

const StyledLink = styled.a`
  font-size: 14px;
  font-weight: 600;
  color: #000;
  background: #fff;
  display: inline-block;
  padding: 10px 0px;
  display: inline-flex;
  align-items: center;
`

export default props => {
  const { taxonomy, pageContext, rtl, showThumbnails, scrollPosition, titleField = "name" } = props
  const { site } = pageContext
  const { content = "" } = taxonomy
  const readMore = site["read-more"] || "Read more"
  const countryCode = taxonomy?.properties?.["cldr-code"]?.data?.value?.replace("country.","")?.toLowerCase()
  return (
    <Container className={props.className}>
      {showThumbnails && taxonomy?.properties?.image?.resolved?.[0]?.url ? (
        <Thumbnail className="mb-2">
          <LazyLoadImage scrollPosition={scrollPosition} src={cloudinary.url(taxonomy?.properties?.image?.resolved?.[0]?.url , { width: 280, height: 280, crop: "pad" })} alt={taxonomy?.["list-title"] ? taxonomy?.["list-title"] : taxonomy.name} />
        </Thumbnail>
      ) : null}
      <div className="d-flex my-3">
        {countryCode ? <LazyLoadImage scrollPosition={scrollPosition} src={`/flags/${countryCode}.svg`} alt={`${countryCode} flag`} className={"flagIcon " + (rtl ? "ml-3":"mr-3")}/>:null}
        <h2 className="my-0 title">
          <a href={taxonomy.path} className="mb-1 title">
            {taxonomy?.["list-title"] ? taxonomy?.["list-title"] : taxonomy[titleField]}
          </a>
        </h2>
      </div>

      <Excerpt
        dangerouslySetInnerHTML={{ __html: content }}
        maxHeight={"180px"}
        isExcerpt={true}
      />
      <StyledLink href={taxonomy.path} className="readMoreLink">
        {readMore}
        {rtl ? null : <ArrowRight />}
      </StyledLink>
    </Container>
  )
}

import React from "react"
import styled from "@emotion/styled";

const Container = styled.div`
  a {
    color: ${props => props.theme.secondaryColor};
  }

  table {
    width: 100%;
  }

  th {
    background: ${props => props.theme.primaryColor};
    font-size: 11px;
    color: #fff;
    font-weight: normal;
  }

  th, td {
    text-align: left;
    padding: 12px 15px;
    font-size: 15px;
  }

  tr:nth-of-type(odd) {
    background-color: ${props => props.theme.tertiaryColor};
  }
`

const Th = styled.th`
  border-top-left-radius: ${props => props.first ? props.theme.borderRadius: "0"};
  border-bottom-left-radius: ${props => props.first ? props.theme.borderRadius: "0"};
  border-top-right-radius: ${props => props.last ? props.theme.borderRadius: "0"};
  border-bottom-right-radius: ${props => props.last ? props.theme.borderRadius: "0"};
`

const Td = styled.td`
  border-top-left-radius: ${props => props.first ? props.theme.borderRadius: "0"};
  border-bottom-left-radius: ${props => props.first ? props.theme.borderRadius: "0"};
  border-top-right-radius: ${props => props.last ? props.theme.borderRadius: "0"};
  border-bottom-right-radius: ${props => props.last ? props.theme.borderRadius: "0"};
`

function shuffle(array, seed) {
  var m = array.length, t, i;

  // While there remain elements to shuffle…
  while (m) {

    // Pick a remaining element…
    i = Math.floor(random(seed) * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed
  }

  return array;
}

function random(seed) {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}


const ProviderTable = props => {
  let { pageContext, providers = [], path = "index" } = props
  const {
    site,
  } = pageContext

  if (!providers?.length) return null
  providers = shuffle(JSON.parse(JSON.stringify(providers)), path?.charCodeAt(1))?.slice(0, 10)

  return (
    <Container>
      <table>
        <tbody>
          <tr>
            <Th className="text-center" first>{site?.["rank-heading"] || "Rank"}</Th>
            <Th>{site?.["provider-type-heading"] || "Casino"}</Th>
            <Th>{site?.["bonus-heading"] || "Bonus"}</Th>
            <Th className="text-center" last>{site?.["rating-heading"] || "Rating"}</Th>
          </tr>
        </tbody>
        {providers?.map((p, i) => {
          return (<tr>
            <Td className="text-center" first>{i + 1}</Td>
            <Td><a href={p.path}>{p.name}</a></Td>
            <Td>{p?.["bonus"] || p?.["bonus-text"]}</Td>
            <Td className="text-center" last>{p?.properties?.["total-score"]?.data?.value}</Td>
          </tr>)
        })}
      </table>
    </Container>
  )
}

export default ProviderTable
